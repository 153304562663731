import { render, staticRenderFns } from "./SetPhoto.vue?vue&type=template&id=4ad027ff&scoped=true&"
import script from "./SetPhoto.vue?vue&type=script&lang=js&"
export * from "./SetPhoto.vue?vue&type=script&lang=js&"
import style0 from "./SetPhoto.vue?vue&type=style&index=0&id=4ad027ff&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ad027ff",
  null
  
)

export default component.exports