<template>
  <div class="pop-check" ref="popDom">
    <div class="main">
      <div class="check-title">
        <strong>{{ title }}</strong>
        <span class="close" @click="closePop"></span>
      </div>
      <div class="check-main">
        <div class="target-photo" v-if="photoList">
          <img v-lazy="defaultImg" />
        </div>
        <ul class="photo-list" v-if="photoList">
          <li v-for="(item, index) in photoList" :key="index" class="photo-list-li"
            :class="{ hover: index === photoIndex }" @click="selectImg(item, index)">
            <img :src="item" />
          </li>
          <li class="photo-list-li">
            <el-upload action="" list-type="picture-card" accept=".jpg,.gif,.png,.jpeg" :file-list="fileList" :limit="1"
              :http-request="upFileList" :on-remove="handleRemove" :on-change="handFileList" :auto-upload="false"
              :multiple="true" ref="upload">
              <i class="el-icon-plus"></i>
            </el-upload>
          </li>
        </ul>
      </div>
      <div class="photo-link">
        <el-button type="primary" class="link" @click="submitPhoto">{{ $t('components.SetPhoto.207060-0') }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      photoIndex: 0,
      defaultImg: "",
      photoList: null,
      fileList: [],
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  watch: {
    userInfo(val) {
      if (val) {
        this.initFrom();
      }
    },
  },
  mounted() {
    this.inittest();
  },
  methods: {
    // 上传图片
    upFileList() {
      this.loading = true;
      const params = {
        files: this.fileList[0].raw,
      };
      this.$Api.upLoadImgPost(params).then((res) => {
        if (res) {
          this.loading = false;
          this.$Api.modifyUserInfo({ photoUrl: res[0] }).then((res) => {
            if (res) {
              this.closePop();
              this.$Api.getUserInfo().then((res) => {
                if (res) {
                  this.$store.dispatch("setUserInfoAc", res.userInfo);
                }
              });
            }
          });
        }
      });
    },

    handFileList(file, fileList) {
      fileList.forEach((item, index) => {
        item.typeId = index;
      });
      this.fileList = fileList;
      this.defaultImg = fileList[0].url;
    },
    inittest() {
      const dom = this.$refs.popDom;
      document.body.appendChild(dom);
      const imgs = [];
      for (let i = 1; i < 9; i++) {
        const img = require("@/assets/images/photo/photo" + i + ".png");
        imgs.push(img);
      }
      this.photoList = imgs;
      this.defaultImg = this.userInfo.photoUrl;
    },

    handleRemove(file) {
      this.fileList = this.fileList.filter((item) => {
        if (file !== item) {
          return item;
        }
      });
    },
    selectImg(val, index) {
      this.photoIndex = index;
      this.defaultImg = val;
    },
    submitPhoto() {
      if (this.fileList && this.fileList.length) {
        this.upFileList();
        return;
      }
      const imgPhoto = location.origin + this.defaultImg;
      this.$Api.modifyUserInfo({ photoUrl: imgPhoto }).then((res) => {
        if (res) {
          this.closePop();
          this.$Api.getUserInfo().then((res) => {
            if (res) {
              this.$store.dispatch("setUserInfoAc", res.userInfo);
            }
          });
        }
      });
    },

    closePop() {
      this.$emit("update:visible");
    },
  },
};
</script>

<style lang="scss" scoped>
.pop-check {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .main {
    background-color: #fff;
    width: 480px;
    border-radius: 20px;
    overflow: hidden;
  }

  .check-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 48px;
    padding: 0 32px;
    background: #179cff;
    position: relative;

    strong {
      font-size: 18px;
      font-weight: normal;
    }

    .close {
      position: absolute;
      top: 15%;
      right: 24px;
      width: 34px;
      height: 34px;
      // transform: translateY(-50%);
      display: block;
      background: url("@/assets/images/pay/close01.png") no-repeat center center;
      background-size: 56%;
      cursor: pointer;
      transition: all 0.4s;

      &:hover {
        transform: rotate(-90deg);
      }
    }
  }
}

.check-main {
  padding: 30px;
}

.target-photo {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  overflow: hidden;
  margin: 20px auto 30px;

  img {
    width: 100%;
    height: 100%;
  }
}

.photo-list {
  display: flex;
  flex-wrap: wrap;

  .photo-list-li {
    width: 64px;
    height: 64px;
    border-radius: 100%;
    border: 2px solid transparent;
    margin-right: 7px;
    margin-bottom: 16px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;

    &.hover,
    &:hover {
      border-color: #179cff;
      z-index: 2;

      &::before {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        border: 5px solid transparent;
        border-bottom-color: #179cff;
      }
    }

    &:nth-child(6n + 6) {
      margin-right: 0;
      overflow: hidden;
    }

    &:last-child {
      background: url("@/assets/images/xj01.png") no-repeat center center #eee;
      background-size: 24px auto;

      &::before {
        display: none;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.photo-link {
  width: 70%;
  margin: 0 auto 30px;

  .link {
    width: 100%;
  }
}

/deep/.el-upload--picture-card {
  background: none;
  border: none;
  width: 60px;
  height: 60px;

  &:hover {
    border: none;
  }

  .el-icon-plus {
    display: none;
  }
}

/deep/.el-upload-list--picture-card .el-upload-list__item {
  border-radius: 100%;
}
</style>
