<template>
  <div class="yx-wid yx-bg">
    <div class="yx-wid banner">
      <img
        v-lazy="item.imgUrl"
        v-for="(item, index) in banners"
        :key="index"
        @click="openBanner(item)"
        :class="{ hover: index === bannerInd }"
      />
      <!-- 快捷banner导航 -->
      <div class="banner-nav">
        <span
          v-for="(item, index) in banners"
          :key="index"
          :class="{ hover: index === bannerInd }"
          @click="tabBanner(item, index)"
        ></span>
      </div>
      <!-- 左右切换 -->
      <div class="banner-prev" @click="tabPrev"></div>
      <div class="banner-next" @click="tabNext"></div>
    </div>
    <!-- 跑马灯 -->
    <div class="yx-wid home-ad">
      <div class="yx-wid-12">
        <div class="home-ad-icon"></div>
        <div class="home-ad-scroll" v-if="newsList" ref="scorll">
          <div
            class="home-ad-text"
            @mouseout="scrollStar"
            @mouseover="moveScroll"
            ref="scorllWid"
            :style="{
              transform: 'translateX(' + scorllLeftVal + 'px)',
              transition: scrollLeftStar,
            }"
          >
            <div
              class="row"
              v-for="(item, index) in newsList"
              @click="openMessage(item)"
              :key="index"
              v-html="item.scrollContent"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 俱乐部 -->
    <section class="yx-wid wow animate__animated animate__fadeInUp">
      <div class="yx-wid-12 blues-box">
        <div
          v-for="(item, index) in blues"
          class="blues-item"
          :key="index"
          @click="$router.push('/brand?type=' + (index + 1))"
        >
          <img :src="item.img" />
        </div>
      </div>
    </section>
    <!-- APP下载 -->
    <section class="yx-wid-12 bd-download-box">
      <div class="bandao-title wow animate__animated animate__fadeInUp">
        <img src="@/assets/images/title-download.png" />
      </div>
      <div class="bd-download wow animate__animated animate__fadeInUp">
        <div class="bd-download-left">
          <img src="@/assets/images/download.png" />
        </div>
        <div class="bd-download-right">
          <ul class="bd-download-menus">
            <li
              v-for="(item, index) in loads"
              @click="loadIndex = index"
              :class="{ hover: index === loadIndex }"
              :key="index"
            >
              {{ item.name }}
            </li>
          </ul>
          <div class="bd-download-title">
            <h3>{{ loads[loadIndex].name }}</h3>
            <p>{{ loads[loadIndex].sec }}</p>
          </div>
          <div class="bd-download-link" v-if="baseInfo && baseInfo[21]">
            <div class="bd-download-link-item">
              <QRCode
                :text="baseInfo[22].configValue"
                v-show="loadIndex === 0"
                :width="100"
                :height="100"
              />
              <QRCode
                :text="baseInfo[21].configValue"
                v-show="loadIndex === 1"
                :width="100"
                :height="100"
              />
              <p>{{ $t('views.index.693796-0') }}</p>
            </div>
            <div class="bd-download-link-item">
              <!-- <img src="@/assets/images/download-icon.png" /> -->
              <a :href="baseInfo[22].configValue" v-show="loadIndex === 0">{{
                baseInfo[22].configValue
              }}</a>
              <a :href="baseInfo[21].configValue" v-show="loadIndex === 1">{{
                baseInfo[21].configValue
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 游戏类型 -->
    <section class="yx-wid-12">
      <div class="bandao-title wow animate__animated animate__fadeInUp">
        <img src="@/assets/images/title-game.png" />
      </div>
      <div
        class="bd-game-box wow animate__animated animate__fadeInUp"
        v-if="hotsGameList && hotsGameList.length"
      >
        <ul class="bd-game-tab">
          <li
            v-for="(item, index) in hotsGameList"
            @click="tabHotsGame(item, index)"
            :class="{ hover: index === hotsGameInd }"
            :style="{
              left: index * 120 + 'px',
              'z-index': hotsGameList.length + 2 - index,
            }"
            :key="index"
          >
            <img
              :src="
                require('@/assets/images/home/' + item.layout + '-icon.png')
              "
              class="bd-game-tab-img"
            />
            {{ item.gameTypeName }}
          </li>
        </ul>
        <div class="bd-game-main">
          <img
            :src="
              require('@/assets/images/home/' +
                hotsGameList[hotsGameInd].layout +
                '.png')
            "
          />
          <p>{{ hotsGameList[hotsGameInd].gameDescription }}</p>
          <ul>
            <li
              v-for="(item, index) in hotsGameList[hotsGameInd].list"
              :key="index"
              @click="openGame(item, index)"
            >
              <img :src="item.icon" class="xz" />
              <span>{{ item.apiTitle }}</span>
            </li>
          </ul>
        </div>
        <div class="bd-game-img">
          <img v-lazy="hotsGameList[hotsGameInd].imageUrl" />
        </div>
      </div>
    </section>

    <!-- 服务 -->
    <section class="yx-wid-12 bd-server">
      <div class="bandao-title wow animate__animated animate__fadeInUp">
        <img src="@/assets/images/title-server.png" />
      </div>
      <div class="bd-server-list wow animate__animated animate__fadeInUp">
        <div
          class="bd-server-item"
          v-for="(item, index) in serves"
          :key="index"
        >
          <div class="bd-server-item-text">
            <h3 class="name">{{ item.name }}</h3>
            <p class="name-en">{{ item.en }}</p>
            <p class="sec">{{ item.sec }}</p>
          </div>
          <div class="bd-server-item-number">
            <div>
              <span>{{ item.number }}</span>
              <i>{{ item.unit }}</i>
            </div>
            <p>{{ item.time }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import QRCode from "@/components/QRCode.vue";
import { mapState } from "vuex";
export default {
  components: { QRCode },
  name: "Home",
  data() {
    return {
      bannerInd: 0,
      bannerTimer: null,
      loads: [
        {
          name: "IOS APP",
          title: "iOS APP",
          code: "iosApp",
          type: 1,
          sec: this.$t('views.index.693796-1'),
          link: "",
          class: "move",
        },
        {
          name: "Andriod APP",
          title: "andriod APP",
          code: "androidApp",
          type: 2,
          sec: this.$t('views.index.693796-2'),
          link: "",
          class: "",
        },
      ],
      loadIndex: 0,
      hotsGameInd: 0,
      newsList: null,
      banners: null,
      scorllLeftVal: 0,
      scrollLeftStar: "all .4s",
      timerText: null,
      scorllWid: 0,
      isScroll: false,
      // 俱乐部
      blues: [
        // {
        //   img: require('@/assets/images/partner01.png'),
        //   link: ''
        // },
        {
          img: require("@/assets/images/brand/banner01.png"),
          link: "/brand?type=1",
        },
        {
         img: require("@/assets/images/brand/banner02.png"),
         link: ''
         },
        // {
        //   img: require('@/assets/images/partner04.png'),
        //   link: ''
        // },
        // {
        //   img: require('@/assets/images/partner05.png'),
        //   link: ''
        // },
        // {
        //   img: require('@/assets/images/partner06.png'),
        //   link: ''
        // }
      ],
      // 服务
      serves: [
        {
          name: this.$t('views.index.693796-3'),
          en: "MORE WAYS TO PROTECT",
          sec: this.$t('views.index.693796-4'),
          number: 60,
          unit: this.$t('views.index.693796-5'),
          time: this.$t('views.index.693796-6'),
        },
        {
          name: this.$t('views.index.693796-7'),
          en: "MORE WAYS TO WIN",
          sec: this.$t('views.index.693796-8'),
          number: 90,
          unit: this.$t('views.index.693796-5'),
          time: this.$t('views.index.693796-9'),
        },
        {
          name: this.$t('views.index.693796-10'),
          en: "MORE DEVICES ACCOUNT",
          sec: this.$t('views.index.693796-11'),
          number: 80,
          unit: this.$t('views.index.693796-12'),
          time: this.$t('views.index.693796-13'),
        },
        {
          name: this.$t('views.index.693796-14'),
          en: "MORE EVENTS TO PLAY",
          sec: this.$t('views.index.693796-15'),
          number: 25,
          unit: this.$t('views.index.693796-12'),
          time: this.$t('views.index.693796-16'),
        },
      ],
      // 首页游戏数据固定化
      homeGameList: null,
    };
  },
  computed: {
    ...mapState(["navsVal", "navsList", "isLogin", "baseInfo", "hotsGameList"]),
  },
  watch: {},
  mounted() {
    this.getTop50MessageList();
    this.getSilde();
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
    });
    wow.init();
  },
  methods: {
    // 基础信息赋值给data上
    initBaseInfo() {
      if (!this.baseInfo) return;
      this.loads.forEach((item) => {
        if (item.type === 1) {
          item.link = this.baseInfo.find(
            (item) => item.configKey === "androidApp"
          ).configValue;
        }
        if (item.type === 2) {
          item.link = this.baseInfo.find(
            (item) => item.configKey === "iosApp"
          ).configValue;
        }
      });
    },
    viewMessage() {
      this.$router.push("/mail");
    },
    openMessage(val) {
      this.$confirm(val.content, val.title, {
        confirmButtonText: this.$t('views.index.693796-17'),
        customClass: "el-pop-wid-message",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          if (!this.isLogin) return;
          this.$router.push("/mail");
        })
        .catch(() => {
          console.log(11);
        });
    },
    // banner
    getSilde() {
      const params = {
        position: 1,
        device: 1,
      };
      this.$Api.getSilde(params).then((res) => {
        if (res) {
          this.banners = res.slideList;
          this.autoBanner();
        }
      });
    },
    // 公告
    getTop50MessageList() {
      this.$Api.getTop50MessageList().then((res) => {
        if (res) {
          this.newsList = res.messageList.filter(
            (item) => item.messageChannel === 0
          );
          this.$nextTick(() => {
            this.scrollInit();
          });
        }
      });
    },
    moveScroll() {
      clearInterval(this.timerText);
    },
    // 跑马灯初始化
    scrollInit() {
      // 获取box宽度
      const dowSocrll = this.$refs.scorll.clientWidth;
      this.scorllWid = this.$refs.scorllWid.clientWidth;
      this.isScroll = this.scorllWid > dowSocrll ? true : false;
      if (this.isScroll) {
        this.newsList.push(...this.newsList);
        this.scrollStar();
      }
    },
    scrollStar() {
      if (!this.isScroll) return;
      this.timerText = setInterval(() => {
        const valNumber =
          this.scorllWid - this.scorllLeftVal < 10
            ? this.scorllWid - this.scorllLeftVal
            : 10;
        this.scorllLeftVal -= valNumber;
        if (this.scorllLeftVal <= -this.scorllWid) {
          this.scorllLeftVal = 0;
          this.scrollLeftStar = "none";
          setTimeout(() => {
            this.scrollLeftStar = "all .4s";
          }, 50);
        }
      }, 120);
    },
    autoBanner() {
      this.bannerTimer = setInterval(() => {
        this.bannerInd++;
        if (this.bannerInd >= this.banners.length) {
          this.bannerInd = 0;
        }
      }, 5000);
    },
    tabBanner(val, index) {
      clearInterval(this.bannerTimer);
      this.bannerInd = index;
      this.autoBanner();
    },
    openBanner(val) {
      if (val.activityId != null) {
        this.$router.push("/activity/activityLandPage?type=" + val.activityId);
      }
    },
    tabPrev() {
      clearInterval(this.bannerTimer);
      if (this.bannerInd === 0) {
        this.bannerInd = this.banners.length;
      }
      this.bannerInd--;
      this.autoBanner();
    },
    tabNext() {
      clearInterval(this.bannerTimer);
      this.bannerInd++;
      if (this.bannerInd === this.banners.length) {
        this.bannerInd = 0;
      }
      this.autoBanner();
    },
    // 打开游戏
    openGame(val, index) {
      // 游戏列表数量超过3个
      if (this.hotsGameList[this.hotsGameInd].list.length > 3 && index === 2) {
        const link = this.hotsGameList.find(
          (item) => Number(item.gameType) === val.gameType
        ).link;
        this.$router.push({
          path: link,
        });
        return;
      }
      if (val.gameType === 2) {
        this.$router.push({
          path: "/game/egame",
          query: {
            code: val.platType,
          },
        });
        this.isShowSelect = false;
        return;
      }
      if (val.gameType === 6) {
        this.$router.push({
          path: "/game/fishing",
          query: {
            code: val.platType,
          },
        });
        this.isShowSelect = false;
        return;
      }
      if (!this.isLogin) {
        this.$confirm(this.$t('views.index.693796-18'), this.$t('views.index.693796-19'), {
          confirmButtonText: this.$t('views.index.693796-20'),
          showCancelButton: false,
          customClass: "el-login-pop",
        })
          .then(() => {
            this.$router.push("/user/login");
          })
          .catch(() => {
            console.log(11);
          });
        return;
      }

      const params = {
        platType: val.platType,
        gameType: val.gameType,
        gameCode: val.gameCode,
        devices: 0,
      };
      this.$Api.getGameUrl(params).then((res) => {
        if (res) {
          const url = JSON.parse(res.data);
          window.open(url.url, "_blank");
        }
      });
    },
    tabHotsGame(val, index) {
      this.hotsGameInd = index;
    },
  },
  destroyed() {
    clearInterval(this.moveScroll);
  },
};
</script>

<style lang="scss" scoped>
.banner {
  height: 700px;
  position: relative;
  overflow: hidden;
  margin-top: 74px;

  &:hover {
    .banner-next,
    .banner-prev {
      display: block;
    }
  }

  img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    height: 100%;
    opacity: 0;
    transition: all 0.8s;

    &.hover {
      opacity: 1;
      transform: translateX(-50%);
      z-index: 1;
    }
  }

  &-nav {
    white-space: nowrap;
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    span {
      width: 24px;
      height: 5px;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      margin: 0 6px;
      background: linear-gradient(
        180deg,
        #e9f0f7 9.6%,
        #a8c0d5 53.12%,
        #e5eef5 94.25%
      );
      box-shadow: 0 1px 2px #fff, 0 -1px 0 #c8d6e3, 0 4px 4px #dce7f0;
      overflow: hidden;
      border-radius: 3px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        bottom: 0;
      }

      &.hover {
        width: 40px;
        background: linear-gradient(
          180deg,
          #d1e6ff 11.07%,
          #3590ff 58.13%,
          #95d9ff
        );
        box-shadow: 0 1px 2px #fff, 0 -1px 0 #87c3ff, 0 4px 4px #bad8fd;
      }
    }
  }
}

@keyframes star {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.banner-prev,
.banner-next {
  position: absolute;
  top: 50%;
  transform: translateY(-80%);
  width: 40px;
  height: 80px;
  background: rgba(4, 26, 54, 0.3);
  border-radius: 0 8px 8px 0;
  backdrop-filter: blur(5px);
  z-index: 2;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border: 2px solid transparent;
    border-left-color: white;
    border-top-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    background: rgba(4, 26, 54, 0.3);
  }
}

.banner-next {
  right: 0;
  border-radius: 8px 0 0 8px;

  &::before {
    transform: translate(-50%, -50%) rotate(135deg);
  }
}

.home-ad {
  .yx-wid-12 {
    display: flex;
    align-items: center;
    padding: 10px 24px 10px 56px;
    background: linear-gradient(
      180deg,
      #edf7ff,
      #fff 44.4%,
      #fbfdff 76.68%,
      #f2faff 93.87%
    );
    box-shadow: 0 3px 6px 0 rgba(111, 157, 200, 0.35), inset 0 1px 1px 0 #fff,
      inset 0 -2px 1px 0 #aecce4, inset 0 -1px 0 0 #93b6d3;
    border-radius: 6px;
    position: relative;
    z-index: 2;
  }

  &-icon {
    width: 44px;
    height: 44px;
    margin-right: 6px;
    background: url("@/assets/images/icon-notice.webp") no-repeat center center;
    background-size: cover;
    position: absolute;
    top: 0;
    margin-top: -8px;
    left: 0;
  }

  &-scroll {
    flex: 1;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    color: #3f5075;
    position: relative;
  }

  &-text {
    display: inline-block;
    white-space: nowrap;
    // width: 100%;
    // animation: amin 10s linear infinite;

    .row {
      display: inline-block;
      vertical-align: middle;
      margin-right: 20px;
      cursor: pointer;
    }
  }

  &-img {
    margin-top: 70px;
    cursor: pointer;
    text-align: center;

    img {
      transition: all 0.4s;

      &:hover {
        transform: scale(1.04);
      }
    }
  }
}

.home-load-flex {
  display: flex;
  align-items: center;

  .img {
    width: 880px;
  }

  .flex-left {
    flex: 1;

    .img {
      width: 115%;
      margin-left: -15%;
    }
  }

  .flex-right {
    background: url("@/assets/images/load-bg.png") no-repeat;
    background-size: 100% 100%;
    width: 456px;
    height: 580px;
    box-shadow: 0 6px 20px 3px rgba(201, 211, 237, 0.5);
    border-radius: 24px;
    flex: none;
    padding: 36px 24px;

    .load-tab {
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      li {
        width: 132px;
        height: 44px;
        background: url("@/assets/images/tab-bg.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #788b9d;
        font-size: 16px;
        cursor: pointer;
        margin: 0 10px;

        &.hover {
          color: white;
          background: url("@/assets/images/tab-bg1.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .load-sec {
      padding: 0 10px;

      strong {
        font-size: 40px;
        margin-top: 32px;
        display: block;
      }

      p {
        line-height: 28px;
        font-size: 18px;
        margin-top: 12px;
        text-align: justify;
      }
    }

    .load-add {
      display: flex;
      align-items: center;
      padding: 24px 10px 0;

      &-code,
      &-web {
        background: #f4faff;
        border: 1px solid #fff;
        border-radius: 12px;
        padding: 23px 31px 12px;
        height: 224px;
        width: 182px;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;

        .text {
          text-align: center;
          line-height: 22px;

          span {
            font-size: 16px;
            margin-top: 20px;
            display: block;
          }

          p {
            font-size: 12px;
            margin-top: 2px;
            white-space: nowrap;
          }
        }
      }

      &-web {
        margin-left: 22px;

        .add {
          width: 120px;
          height: 120px;

          a {
            color: #2599f8;
            word-wrap: break-word;
          }
        }
      }

      &-code {
        .img {
          width: 120px;
        }
      }
    }
  }
}

.load-add-pc {
  padding: 0 10px;
  margin-top: 32px;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  li {
    width: 120px;
    height: 168px;
    background: #f0f6ff;
    border: 1px solid #fff;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 60%;
    }

    .btn {
      background: url("@/assets/images/tab-bg1.png") no-repeat;
      line-height: 22px;
      height: 22px;
      width: 68px;
      font-size: 12px;
      background-size: 100% 100%;
      cursor: pointer;
      box-shadow: 0 2px 5px #5da7eb;
      border-radius: 11px;
      color: white;
      text-align: center;
      margin-top: 12px;
    }
  }
}

.yx-bg {
  background: url("@/assets/images/home-bg.png") repeat-y center 580px;
  background-size: contain;
  padding-bottom: 80px;
}

.blues {
  &-box {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  &-item {
    cursor: pointer;

    &:hover {
      img {
        transform: scale(1.04);
      }
    }

    img {
      width: 100%;
      transition: all 0.4s;
    }
  }
}

.bandao-title {
  display: flex;
  align-items: center;
  margin: 80px auto 30px;
  width: 1200px;

  img {
    width: 100%;
  }
}

// app下载
.bd-download {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 80px;

  &-box {
    width: 1280px;
  }

  &-left {
    width: 618px;

    img {
      width: 100%;
    }
  }

  &-right {
    border: 1px solid var(--neutral-3050, hsla(0, 0%, 100%, 0.5));
    border-radius: 16px;
    width: 570px;
    min-height: 400px;
    position: relative;
    padding: 30px 30px 40px;
    background: white;
    box-shadow: inset 0 -4px 4px 0 rgba(184, 208, 229, 0.3),
      inset 0 4px 4px 0 rgba(255, 255, 255, 0.9),
      0 6px 12px 0 rgba(180, 206, 228, 0.36), 0 3px 0 0 #c1dbf0;
  }

  &-menus {
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      background: url("@/assets/images/download-btn.png") no-repeat;
      background-size: contain;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #7e92bd;
      width: 164px;
      height: 56px;
      font-size: 18px;
      margin: 0 8px;
      cursor: pointer;
      padding-bottom: 3px;

      &.hover {
        background: url("@/assets/images/download-btn1.png") no-repeat;
        background-size: contain;
        color: white;
      }
    }
  }

  &-title {
    h3 {
      font-size: 40px;
      margin: 20px 0 10px;
      line-height: 48px;
    }

    p {
      color: #79849e;
      line-height: 28px;
      font-size: 18px;
    }
  }

  &-link {
    display: flex;
    align-items: center;
    margin-top: 30px;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: linear-gradient(
        101deg,
        #f4faff 0.99%,
        #eef7ff 44.09%,
        #eff7ff 98.54%
      );
      box-shadow: inset 1px 1px 0 0 #fff;
      border: 1px solid #fff;
      border-radius: 12px;
      padding: 10px;
      width: 162px;
      margin-right: 20px;
      height: 170px;

      img {
        width: 100px;
      }

      p,
      a {
        color: #79849e;
        margin-top: 20px;
      }

      a {
        text-decoration: underline;
        white-space: normal; /* 保留空白符序列，但是正常换行。*/
        word-break: break-all;
      }
    }
  }
}

// 游戏
.bd-game {
  &-box {
    width: 898px;
    min-height: 508px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 60px;

    &::before,
    &::after {
      position: absolute;
      content: "";
    }

    &::before {
      background: url("@/assets/images/home-game-yz.png") no-repeat;
      background-size: cover;
      bottom: -12px;
      left: -155px;
      width: 245px;
      height: 253px;
      display: inline-block;
      z-index: 1;
    }

    &::after {
      background: url("@/assets/images/home-game-yz1.png") no-repeat;
      background-size: cover;
      top: -40px;
      right: -348px;
      width: 380px;
      height: 190px;
      display: inline-block;
      z-index: 1;
    }
  }

  &-img {
    position: absolute;
    left: 484px;
    top: 30px;
    height: 540px;
    width: 768px;
    z-index: 10;

    img {
      width: 100%;
    }
  }

  &-tab {
    display: flex;
    align-items: center;
    position: relative;
    height: 44px;

    &-img {
      width: 28px;
      filter: grayscale(80%);
      opacity: 0.7;
      transition: all 0.2s;
      margin-right: 8px;
    }

    li {
      width: 178px;
      height: 48px;
      background: url("@/assets/images/home-game-tab.png");
      background-size: cover;
      padding-left: 45px;
      font-size: 18px;
      color: #79849e;
      display: flex;
      align-items: center;
      position: absolute;
      cursor: pointer;
      font-weight: 500;

      &:last-child {
        background: url("@/assets/images/home-game-tab3.png");
        background-size: cover;
      }

      &.hover {
        background: url("@/assets/images/home-game-tab4.png");
        background-size: cover;
        z-index: 9 !important;

        img {
          width: 40px;
          filter: grayscale(0);
          opacity: 1;
        }

        &:first-child {
          background: url("@/assets/images/home-game-tab1.png");
          background-size: cover;
        }

        &:last-child {
          background: url("@/assets/images/home-game-tab2.png");
          background-size: cover;
        }
      }
    }
  }

  &-main {
    background: linear-gradient(
        228deg,
        rgba(240, 248, 255, 0.6) 5.33%,
        hsla(0, 0%, 100%, 0.6) 52.21%
      ),
      linear-gradient(9deg, #edf7ff 1.16%, #fdfeff 87.08%);
    box-shadow: inset 0 -4px 4px 0 rgba(184, 208, 229, 0.3),
      inset 0 4px 4px 0 rgba(255, 255, 255, 0.9),
      0 6px 12px 0 rgba(180, 206, 228, 0.36), 0 3px 0 0 #c1dbf0;
    flex: 1 1;
    display: flex;
    border-radius: 0 0 16px 16px;
    border: 1px solid var(--neutral-3050, hsla(0, 0%, 100%, 0.5));
    padding: 61px 430px 50px 38px;
    flex-direction: column;
    position: relative;
    z-index: 1;

    img {
      height: 130px;
    }

    p {
      color: #79849e;
      margin: 12px 0 30px;
      line-height: 150%;
      font-size: 16px;
      text-align: justify;
    }

    ul {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 10px;
      min-height: 162px;

      li {
        height: 60px;
        border-radius: 6px;
        position: relative;
        background: linear-gradient(
          228deg,
          rgba(252, 254, 255, 0.6) 5.33%,
          #eff4ff 52.21%,
          white
        );
        box-shadow: inset 0 -4px 4px 0 rgba(255, 255, 255, 1),
          inset 0 4px 4px 0 rgba(255, 255, 255, 0.9),
          0 6px 12px 0 rgba(180, 206, 228, 0.36), 0 2px 0 0 #f0f8ff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        border: 1px solid #fafdff;
        cursor: pointer;

        &:hover {
          background: linear-gradient(
            180deg,
            #85c0ff,
            #65b1fc 70%,
            #24c8ff 93.87%
          );
          box-shadow: 0 3px 6px 0 rgba(68, 146, 213, 0.35),
            inset 0 1px 1px 0 #6affeb00, inset 0 -2px 1px 0 #4bafff,
            inset 0 -1px 0 0 #97bfe0;
          color: #2599f8;
          border-color: transparent;

          img {
            filter: invert(0%);
          }
        }

        img {
          width: 75%;
          height: auto;
          filter: invert(30%);
        }

        span {
          position: absolute;
          bottom: -24px;
          left: 50%;
          white-space: nowrap;
          transform: translateX(-50%);
          font-size: 12px;
          color: #79849e;
        }
      }
    }
  }
}

// 服务
.bd-server {
  margin-top: 120px;

  &-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 60px;
  }

  &-item {
    width: 296px;
    height: 466px;
    text-align: center;
    background: url("@/assets/images/server-bg.png") no-repeat;
    background-size: 100%;

    &-text {
      height: 210px;

      .name {
        padding-top: 30px;
        font-size: 22px;
        color: #3f5075;
        line-height: 31px;
        font-weight: 500;
      }

      .name-en {
        color: #3aa6fc;
        width: 184px;
        margin: 8px auto 0;
        font-size: 14px;
        font-weight: 600;
      }

      .sec {
        color: #3f5075;
        font-size: 14px;
        width: 184px;
        line-height: 20px;
        margin: 16px auto 0;
      }
    }

    &-number {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 296px;
      height: 183px;
      color: #3f5075;

      span {
        background: linear-gradient(
          0deg,
          #40bbff 12.37%,
          #48a0ff 28.14%,
          #48a0ff 46.91%,
          #7dd3ff 74.73%
        );
        background-clip: text;
        color: transparent;
        font-size: 74px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -3px;
      }

      i {
        font-style: normal;
        font-size: 13px;
        line-height: 1;
      }

      p {
        margin-top: -16px;
      }
    }
  }
}
</style>
